
/** Header **/

.site-header {position: relative;height: 60px;border-bottom: 1px solid $border-color;}
.logo {margin: 0;position: relative;padding: 0;}
.logo span {
    background-image: url("../images/waf-logo.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: block;
    height: 59px;
    text-indent: -9999px;
    width: 140px;
    background-position: center;
}


/** Header right control **/
.site-header{position: relative;z-index: 11;background-color: #fff;}
.site-header .cms-action {display: table-cell;height: 59px;vertical-align: middle;}
.site-header .cms-action .dropdown-menu {left: auto;margin-top: 4px;right: 0;top: 36px;}
.site-header .action-icon-btn.user-btn {background-color: #e5e5e5;border-radius: 50%;}

    
