body,
html {
    position: relative;
    font-family: $regular_font-family;
}

.site {
    position: relative;
    overflow-x: hidden;
    min-height: 100vh;
}

.site-content {
    height: calc(100% - 60px);
    position: relative;
}

.nav-tabs {
    li {
        &.active {
            a {
                background-color: #337ab7;
                color: #ffffff;
            }
        }
    }
}

.progress {
    height: 5px;
    .progress-bar,
    .progress-bar-info {
        background-color: $primary-color;
    }
}

th,
td {
    vertical-align: middle;
}

.element-inline {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
}

.td-2 {
    .row-data {
        td {
            &:first-child {
                max-width: 25%;
                width: 100%;
            }
            // &:last-child{
            //   max-width: 25%;
            // }
        }
    }
}

.disable-state {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
}

.nodata {
    margin: 10px;
    text-align: center;
    background-color: #e5e5e5;
    padding: 20px;
}

.loader {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    top: 0;
    img {
        position: absolute;
        width: 50px;
        height: 50px;
        z-index: 1;
        top: 90px;
    }
}


/* _ Card _*/

.card {
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    .card-body {
        position: relative;
        display: flex;
        flex-direction: column;
    }
}


/*_ Buttons _*/

.waf-cms-control {
    .btn {
        margin: 0 5px;
    }
}


/*_ Flexbox _*/

.d-flex {
    @include flexbox();
}

.h-center {
    @include justify-content(center);
}

.v-center {
    @include align-items(center);
}


/*_ checkbox _*/

.checkbox {
    &.checkbox-v2 {
        label {
            display: inline-block;
            max-width: 100%;
            width: auto;
            margin-bottom: 5px;
            padding-left: 0;
            font-weight: bold;
        }
        input[type="checkbox"] {
            width: auto;
            margin-left: 25px;
        }
    }
}


/*_ To create space between label and input type _*/

.form-inline {
    label {
        width: 25%;
    }
    // .form-control,
    // input{
    //   margin-left: 3%;
    //   width: 68%;
    // }
}


/*_ _*/

td {
    &.tblPublished,
    &.tblUnPublished {
        &>span {
            &:before {
                content: "";
                font-family: "FontAwesome";
                color: inherit;
                font-size: 14px;
                display: inline-block;
                margin-right: 8px;
            }
        }
    }
    &.tblPublished {
        &>span {
            &:before {
                content: "\f002";
            }
        }
    }
    &.tblUnPublished {
        &>span {
            &:before {
                content: "\f00d";
            }
        }
    }
}

#sortable {
    tr.edit.ui-sortable-handle {
        cursor: move;
    }
}

.DataFromListing {
    .btn {
        margin-bottom: 10px;
        margin: 0 5px 10px;
    }
}

.multicolumn-layout {
    display: flex;
    .column {
        margin: 0 5px;
    }
}

.add-icon,
.remove-icon {
    color: #ffffff;
    background-color: #337ab7;
    border-radius: 100%;
    border: 1px solid #337ab7;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 24px;
    display: block;
    text-align: center;
    cursor: pointer;
}

.remove-icon {
    font-size: 20px;
}


/** Date time picker ***/

.bootstrap-datetimepicker-widget {
    list-style: none
}

.bootstrap-datetimepicker-widget.dropdown-menu {
    margin: 2px 0;
    padding: 4px;
    width: 19em;
}

.tour-mapping-table {
    .table-item-wrapper {
        border: 1px solid #e5e5e5;
        margin-bottom: 20px;
        .table-title {
            padding: 10px 5px;
            font-weight: bold;
            font-size: 16px;
            background: #e5e5e5;
            margin: 0;
        }
    }
    .tblproductdetail {
        padding: 0;
        table {
            border: 1px solid #e5e5e5;
        }
        .sr-no {
            width: 20%;
        }
        .tblName {
            width: 80%;
        }
    }
    thead {
        tr {
            background: whitesmoke;
        }
    }
    tr {
        .sr-no {
            width: 10%;
        }
        .tblPrice,
        .tblCustPrice,
        .tblTotalPrice {
            width: 15%;
        }
        .tblPName {
            width: 50%;
        }
        .tblproductdetail {
            width: 70%;
            padding: 5px;
        }
        .tblSize,
        .tblQty {
            width: 20%;
        }
        .btn-save {
            margin: 10px;
        }
    }
}

.gallery-info {
    .uploaded-image {
        .tblImgDetails {
            p {
                display: inline-block;
                margin: 0;
            }
            input[type=text] {
                margin: 0 0 10px;
            }
        }
        .tblDescription {
            .img-desc {
                display: inline-block;
                margin: 0;
            }
            .tooltip-text {
                &:after {
                    top: 35%;
                }
            }
        }
        .tooltip {
            position: relative;
            opacity: 1;
            display: inline-block;
            padding-left: 5px;
            z-index: unset;
            &-icon {
                cursor: pointer;
                i {
                    font-size: 16px;
                }
            }
            &-text {
                position: absolute;
                max-width: 250px;
                width: 250px;
                background: #333;
                color: #fff;
                padding: 5px 10px;
                border-radius: 5px;
                left: 40px;
                top: -20px;
                opacity: 0;
                transition: .3s all;
                visibility: hidden;
                z-index: 1;
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    margin-left: -15px;
                    border-width: 8px;
                    border-style: solid;
                    border-color: transparent #333 transparent transparent;
                }
            }
            &:hover {
                .tooltip-text {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

}
@media (min-width:768px) {
    .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
        width: 38em
    }
}

@media (min-width:992px) {
    .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
        width: 38em
    }
}

@media (min-width:1200px) {
    .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
        width: 38em
    }
}

.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
    content: '';
    display: inline-block;
    position: absolute
}

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    top: -7px;
    left: 7px
}

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    top: -6px;
    left: 8px
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #ccc;
    border-top-color: rgba(0, 0, 0, 0.2);
    bottom: -7px;
    left: 6px
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
    bottom: -6px;
    left: 7px
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
    left: auto;
    right: 6px
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
    left: auto;
    right: 7px
}

.bootstrap-datetimepicker-widget .list-unstyled {
    margin: 0
}

.bootstrap-datetimepicker-widget a[data-action] {
    padding: 6px 0
}

.bootstrap-datetimepicker-widget a[data-action]:active {
    box-shadow: none
}

.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
    width: 54px;
    font-weight: bold;
    font-size: 1.2em;
    margin: 0
}

.bootstrap-datetimepicker-widget button[data-action] {
    padding: 6px
}

.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Increment Hours"
}

.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Increment Minutes"
}

.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Decrement Hours"
}

.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Decrement Minutes"
}

.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Show Hours"
}

.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Show Minutes"
}

.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Toggle AM/PM"
}

.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Clear the picker"
}

.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Set the date to today"
}

.bootstrap-datetimepicker-widget .picker-switch {
    text-align: center
}

.bootstrap-datetimepicker-widget .picker-switch::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Toggle Date and Time Screens"
}

.bootstrap-datetimepicker-widget .picker-switch td {
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    line-height: inherit
}

.bootstrap-datetimepicker-widget .picker-switch td span {
    line-height: 2.5;
    height: 2.5em;
    width: 100%
}

.bootstrap-datetimepicker-widget table {
    width: 100%;
    margin: 0
}

.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
    text-align: center;
    border-radius: 4px
}

.bootstrap-datetimepicker-widget table th {
    height: 20px;
    line-height: 20px;
    width: 20px
}

.bootstrap-datetimepicker-widget table th.picker-switch {
    width: 145px
}

.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
    background: none;
    color: #777;
    cursor: not-allowed
}

.bootstrap-datetimepicker-widget table th.prev::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Previous Month"
}

.bootstrap-datetimepicker-widget table th.next::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: "Next Month"
}

.bootstrap-datetimepicker-widget table thead tr:first-child th {
    cursor: pointer
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
    background: #eee
}

.bootstrap-datetimepicker-widget table td {
    height: 54px;
    line-height: 54px;
    width: 54px
}

.bootstrap-datetimepicker-widget table td.cw {
    font-size: .8em;
    height: 20px;
    line-height: 20px;
    color: #777
}

.bootstrap-datetimepicker-widget table td.day {
    height: 20px;
    line-height: 20px;
    width: 20px
}

.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
    background: #eee;
    cursor: pointer
}

.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
    color: #777
}

.bootstrap-datetimepicker-widget table td.today {
    position: relative
}

.bootstrap-datetimepicker-widget table td.today:before {
    content: '';
    display: inline-block;
    border: solid transparent;
    border-width: 0 0 7px 7px;
    border-bottom-color: #337ab7;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #337ab7;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.bootstrap-datetimepicker-widget table td.active.today:before {
    border-bottom-color: #fff
}

.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
    background: none;
    color: #777;
    cursor: not-allowed
}

.bootstrap-datetimepicker-widget table td span {
    display: inline-block;
    width: 54px;
    height: 54px;
    line-height: 54px;
    margin: 2px 1.5px;
    cursor: pointer;
    border-radius: 4px
}

.bootstrap-datetimepicker-widget table td span:hover {
    background: #eee
}

.bootstrap-datetimepicker-widget table td span.active {
    background-color: #337ab7;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.bootstrap-datetimepicker-widget table td span.old {
    color: #777
}

.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
    background: none;
    color: #777;
    cursor: not-allowed
}

.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
    height: 27px;
    line-height: 27px
}

.bootstrap-datetimepicker-widget.wider {
    width: 21em
}

.bootstrap-datetimepicker-widget .datepicker-decades .decade {
    line-height: 1.8em !important
}

.input-group.date .input-group-addon {
    cursor: pointer
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}


/** Select 2 **/

.select2-search__field {
    width: 100% !important;
}

.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
    position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
}

.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-container .select2-search--inline {
    float: left;
}

.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.select2-dropdown {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;
}

.select2-results {
    display: block;
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}

.select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
    cursor: pointer;
}

.select2-container--open .select2-dropdown {
    left: 0;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-search--dropdown {
    display: block;
    padding: 4px;
}

.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
    display: none;
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
    float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield;
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
    padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: white;
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}

.select2-container--classic .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
    background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
    background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: right;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none;
}

.select2-container--classic .select2-dropdown {
    background-color: white;
    border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
    border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
    border-top: none;
}

.select2-container--classic .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
    padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
    color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #3875d7;
    color: white;
}

.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb;
}


/** Form Elements **/

.form-element {
    position: relative;
}

.form-element textarea {
    resize: vertical;
    min-height: 100px;
}

.form-row:before,
.form-row:after {
    clear: both;
    content: "";
    display: table;
}

.form-group {
    position: relative;
}

.info-section {
    padding: 15px;
    blockquote {
        background-color: #fff;
        font-size: 14px;
        p {
            font-size: inherit;
        }
        &:last-child {
            margin-bottom: 0px;
        }
        &.success {
            border-left-color: #3c763d;
        }
        &.warning {
            border-left-color: #8a6d3b;
        }
        &.danger {
            border-left-color: #a94442;
        }
        &.info {
            border-left-color: #31708f;
        }
    }
}

tr.highlight {
    background-color: rgba(0, 0, 255, 0.3);
}


/** Waf Controls **/

.waf-control ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.waf-control li {
    position: relative;
}

.waf-control li>.content-box {
    position: relative;
    padding: 5px 10px;
}


/*_ Multi select _*/

.multiselect-control {
    border: 1px solid #e3e3e3;
    max-height: 200px;
    overflow-y: auto;
    position: relative;
    li>.content-box {
        padding-left: 25px;
    }
    li .content-box:before {
        content: "";
        width: 15px;
        height: 15px;
        border: 1px solid #e1e1e1;
        display: block;
        position: absolute;
        left: 5px;
        font-family: FontAwesome;
        font-size: 10px;
        text-align: center;
        line-height: 15px;
    }
    input {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        margin: 0;
        padding: 0;
        z-index: 2;
        &[type="checkbox"]:checked~.content-box:before {
            content: "\f00c";
        }
        &[type="radio"]:checked~.content-box:before {
            content: "\f058";
        }
    }
}

.multiselect-action {
    margin-top: 10px;
}

.radiolist-control {
    @extend .multiselect-control;
    li .content-box:before {
        border-radius: 50%;
        font-size: 16px;
    }
}

.waf-search {
    padding: 10px 0;
    .form-group {
        margin-bottom: 0;
    }
    .search-field-container .search-field+span {
        &:before {
            content: "\f002";
            display: none;
            position: absolute;
            right: 0;
            height: 30px;
            font-family: "FontAwesome";
            width: 30px;
            text-align: center;
            line-height: 30px;
            color: #666;
        }
        &.select2-container--focus:before {
            display: none;
        }
    }
    .action-icon-btn {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
    }
}

.page-strip-controls .waf-search {
    padding: 0;
}


/*_ Or _*/

.sep {
    border: none;
    height: 1px;
    margin: 30px 0;
    background-color: #ddd;
    position: relative;
    span {
        position: absolute;
        width: auto;
        font-size: 16px;
        color: #666;
        font-family: roboto-bold, sans-serif;
        text-align: center;
        display: inline-block;
        padding: 10px;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: #fff;
        left: 50%;
    }
}


/*_ Panel accordion _*/

.panel-accordion {
    position: relative;
    cursor: pointer;
    .panel-heading {
        position: relative;
    }
    .panel-heading:before {
        content: "\f106";
        font-family: FontAwesome;
        font-size: 20px;
        position: absolute;
        right: 0;
        display: block;
        width: 30px;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
    }
    &.accordion-off {
        .panel-body {
            display: none;
        }
        .panel-heading:before {
            content: "\f107";
        }
    }
}

.panel-body-title {
    margin-top: 0;
    color: #000;
    font-size: 14px;
}


/** Tags **/

.tags-pool {
    margin-top: 5px;
    position: relative;
    color: #666;
    font-size: 0;
}

.tags-pool span {
    display: inline-block;
    vertical-align: middle;
    margin: 2px;
    font-size: 14px;
}

.tags-close {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #666;
    font-size: 12px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 14px;
    margin-right: 3px;
    &:hover {
        color: #fff;
        text-decoration: none;
    }
}


/*_ Tags pool table _*/

.tags-pool-table table {
    border: 1px solid #ddd;
}

.tags-pool-table th,
.tags-pool-table td {
    text-align: center;
    padding: 5px;
    font-size: 12px;
    font-family: "roboto-medium", sans-serif;
    font-weight: normal;
}

.tags-pool-table th,
.tags-pool-table td {
    &.tblPrimary,
    &.tblHidden {
        width: 70px;
    }
}

th.tblName,
td.tblName {
    width: 70%;
    text-align: left;
}

.tags-pool-table .action-icon-btn {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    &.btn-remove {
        &:hover {
            color: red;
        }
    }
}

.tags-pool-table table>tbody>tr>td,
.tags-pool-table table>tbody>tr>th {
    padding: 7px 5px;
    vertical-align: middle;
    line-height: 14px;
}


/** Buttons **/

.action-icon-btn {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 24px;
    color: #121212;
    cursor: pointer;
    position: relative;
    &:hover {
        color: #8DC63F;
        .dropdown-menu {
            display: block;
        }
    }
    &:before {
        content: "";
        font-family: "FontAwesome";
        color: inherit;
    }
    &.user-btn:before {
        content: "\f007";
    }
    &.notification-btn:before {
        content: "\f0f3";
    }
    &.setting-btn:before {
        content: "\f013";
    }
    &.btn-remove:before {
        content: "\f1f8";
    }
    &.btn-cancel:before {
        content: "\f00d";
    }
    &.btn-edit:before {
        content: "\f044";
    }
    &.btn-back:before {
        content: "\f060";
    }
    &.filter-btn:before {
        content: "\f0b0";
    }
    &.sortasc-btn:before {
        content: "\f15d";
    }
    &.sortdsc-btn:before {
        content: "\f15e";
    }
    &.btn-add:before {
        content: "\f055";
    }
    &.btn-filter:before {
        content: "\f0b0";
    }
}

.input-group-btn .action-icon-btn {
    height: 34px;
    line-height: 20px;
    font-size: 16px;
}

.action-btn {
    &:before {
        content: "";
        font-family: "FontAwesome";
        color: inherit;
        font-size: 14px;
        display: inline-block;
        margin-right: 8px;
    }
    &.btn-add:before {
        content: "\f067";
    }
    &.btn-preview:before,
    &.btn-view:before {
        content: "\f06e";
    }
    &.btn-save:before {
        content: "\f0c7";
    }
    &.btn-publish:before {
        content: "\f0ee";
    }
    &.btn-send:before {
        content: "\f1d8";
    }
    &.btn-search:before {
        content: "\f002";
    }
    &.btn-filters:before {
        content: "\f0b0";
    }
    &.btn-remove:before {
        content: "\f1f8";
    }
    &.btn-cancel:before {
        content: "\f00d";
    }
    &.btn-back:before {
        content: "\f060";
    }
    &.btn-news:before {
        content: "\f1ea";
    }
    &.btn-prev:before {
        content: "<";
    }
    &.btn-nxt:after {
        content: ">";
        margin-left: 5px;
    }
    &.btn-print:before {
        content: "\f02f";
    }
    &.btn-email:before {
        content: "\f003";
    }
    &.btn-edit:before {
        content: "\f044";
    }
    &.btn-list:before {
        content: "\f0ca";
    }
    &.btn-grid:before {
        content: "\f00a";
    }
    &.btn-crop:before {
        content: "\f125";
    }
    &.btn-reupload:before {
        content: "\f093";
    }
}


/** Table **/

.table {
    .action-icon-btn {
        width: auto;
        height: auto;
        font-size: 15px;
        vertical-align: middle;
        line-height: 1;
        margin-left: 5px;
    }
}


/** Tabs **/

.waf-tab {
    position: relative;
}

.waf-tab-content {
    padding: 20px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.upload-media-box .nav-tabs {
    padding-left: 15px;
    &.nav-tabs-list {
        padding: 0;
    }
}

.tab-pane {
    position: relative;
}

.nav-tabs {
    &.nav-tabs-list {
        padding: 0;
        display: block;
        border-bottom: none;
        &>li {
            float: none;
            margin-bottom: 0;
            border-bottom: 1px solid #ddd;
            position: relative;
        }
        &>li.active>a,
        &>li.active>a:hover,
        &>li.active>a:focus {
            color: #000;
            background-color: transparent;
            border: 1px solid #ddd;
            border-bottom-color: #ddd;
            cursor: default;
            border-right: none;
            border-left: none;
            border-top: none;
            margin-right: 0;
            border-radius: 0;
        }
        &>li.active {
            &:before {
                content: "";
                position: absolute;
                border-right: 8px solid #fff;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                display: inline-block;
                right: 0;
                top: 50%;
                margin-top: -8px;
            }
        }
    }
}


/** Filters **/

.filters {
    padding: 0 10px;
    border-left: 1px solid #ccc;
    &:first-child {
        padding-left: 0;
        border-left: none;
    }
    &:last-child {
        padding-right: 0;
    }
}


/** Upload box **/

.hover {
    background-color: rgba(0, 0, 255, 0.1);
}

.upload-image-box .hit-area {
    border: 1px dotted #ddd;
    border-radius: 10px;
    height: 90px;
    position: relative;
    text-align: center;
    line-height: 90px;
    font-size: 22px;
    font-family: $medium_font-family;
    color: #999;
    overflow: hidden;
    input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
    }
}

.set-image-preview {
    margin-top: 10px;
    position: relative;
    text-align: left;
    .image-box {
        max-width: 160px;
        position: relative;
        img {
            width: 100%;
            height: auto;
            display: block;
            margin-bottom: 5px;
        }
    }
}


/*_ Video Embed in Posts _*/

// .video-box{
//     width: 350px;
//     height: 196px;
//     position: relative;
//     background-color: #e5e5e5;
//     &:before{
//         font-family: "FontAwesome";
//         content: "\f144";
//         position: absolute;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%,-50%);
//         font-size: 20px;
//         color: red;
//     }
// }

/*_ Upload progress_*/

.progress-status {
    margin-bottom: 15px;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-family: roboto-regular, sans-serif;
}


/*_ Uploaded progress_*/

.uploaded-image {
    .table>thead>tr.success>td,
    .table>tbody>tr.success>td {
        background-color: #FFFFFF;
    }
    .tblImgDetails {
        p {
            font-weight: bold;
        }
        input[type="text"] {
            display: block;
            font-weight: normal;
        }
    }
    .loadederarea {
        height: 20px;
        width: 100%;
        border: 1px solid #cccccc;
        float: left;
        margin-top: 15px;
        .imageload {
            height: 100%;
            width: 100%;
            background-color: rgb(141, 198, 63);
        }
    }
}

//.uploaded-image class
#uploaded-image {}

//#uploaded-image

/** Modals **/


/*_ Upload modals _*/

// .upload-media .modal-header {border-bottom: none;}
.upload-media-box {
    .tabs-block {
        margin: 0 -15px;
    }
    .waf-tab-content {
        border: none;
        padding: 15px;
    }
    .upload-image-box .hit-area {
        font-size: 18px;
    }
}

.upload-bucket,
.media-library {
    margin-top: 10px;
    position: relative;
}

.media-library {
    margin-top: 0;
    padding-top: 30px;
}

.upload-bucket ul,
.media-library ul {
    padding: 0;
    margin: 0 -5px;
    list-style: none;
    .image-box {
        //max-width: 130px;
        height: 100px;
        text-align: center;
        line-height: 100px;
    }
    .image-box img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: auto;
    }
    .image-control {
        margin-top: 8px;
    }
    li {
        float: left;
        width: 25%;
        padding: 0 5px;
        position: relative;
        text-align: center;
        cursor: pointer;
        .content-box {
            border: 1px solid #ddd;
            border-radius: 0;
            padding: 2px;
            margin-bottom: 10px;
        }
        &:hover .content-box {
            background-color: #eee;
        }
        &.active .content-box {
            background-color: #333;
        }
        &.active:hover .content-box {
            background-color: #333;
        }
    }
}


/*_ Upload Media Modal _*/

.upload-media .modal-dialog.insert-modal {
    width: 90%;
}

.upload-media .insert-modal .modal-content .modal-body {
    min-height: 400px;
}

.upload-media .tab-pane {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}


/*_ Upload pane _*/


/*_ LEft Pane _*/

.left-pane {
    width: 200px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #f1f1f1;
    top: 0;
}

.middle-pane {
    margin-left: 185px;
    position: relative;
    margin-right: 334px;
}

.right-pane {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    overflow-y: auto;
    background-color: #f2f2f2;
    width: 350px;
}


/*_ Pane _*/

.pane-heading {
    background-color: #e5e5e5;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    .pane-title {
        font-size: 16px;
        margin: 0;
        color: #000;
    }
}

.pane-body {
    position: relative;
    padding: 10px;
}


/*_ Media Details _*/


/*_ Media search _*/

.media-search {
    position: absolute;
    left: 0;
    top: -30px;
    right: 0;
    background-color: #fff;
    width: auto;
    margin: 0 -15px;
    border-bottom: 1px solid #ddd;
    padding: 0 15px;
}


/*_ Media thumbnails _*/

.media-thumbnail {
    width: 100px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 2px solid #e5e5e5;
    img {
        max-width: 100%;
        width: auto;
        display: block;
    }
}

.media-meta-data span {
    display: block;
}

.media-details .form-inline {
    .form-group {
        margin-bottom: 10px;
    }
    label {
        width: 100px;
        font-weight: normal;
        font-family: "roboto-regular", sans-serif;
        color: #000;
    }
    input[type="text"],
    textarea {
        width: 200px;
    }
}


/*_ Cropped item _*/

.cropped-item {
    width: 75px;
    height: 75px;
    float: left;
    padding: 0 2px;
    position: relative;
    line-height: 75px;
    cursor: pointer;
    .content-box {
        background-color: #333;
        position: relative;
        height: 100%;
        font-size: 0;
    }
    &:hover .content-box,
    &.active .content-box {
        outline: 1px solid red;
    }
    .preview {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        height: 0px;
        vertical-align: middle;
        background-size: auto 100%;
    }
    &.ratio18by6 .preview {
        padding-bottom: 33.33%;
    }
    &.ratio21by9 .preview {
        padding-bottom: 42.85%;
    }
    &.ratio16by9 .preview {
        padding-bottom: 56.25%;
    }
    &.ratio6by4 .preview {
        padding-bottom: 66.66%;
    }
    &.ratio4by3 .preview {
        padding-bottom: 75%;
    }
    &.ratio1by1 .preview {
        padding-bottom: 100%;
    }
    &.ratio2by3 .preview {
        padding-bottom: 150%;
        -webkit-transform: scale(0.65) translateY(-26px);
        -moz-transform: scale(0.65) translateY(-26px);
        -ms-transform: scale(0.65) translateY(-26px);
        transform: scale(0.65) translateY(-26px);
    }
}

.media-meta-info {
    margin-top: 10px;
}

.media-info {
    background-color: #fff;
    padding: 5px;
    margin-bottom: 15px;
    position: relative;
}

.media-action {
    clear: both;
    border-top: 1px solid #ddd;
    padding-top: 4px;
    a {
        display: inline-block;
        padding: 0 10px;
        //border-left: 1px solid #ddd;
        margin: 0 5px;
        &:first-child {
            //padding-left:0;
            //border-left: none;
            margin-left: 0;
        }
        &:last-child {
            //padding-right: 0;
        }
    }
}

.media-ratio-info {
    margin-bottom: 10px;
}


/*_ Media cropper _*/

.ratio-cropper {
    clear: both;
    position: relative;
    &:before,
    &:after {
        clear: both;
        content: "";
        display: table;
    }
    .botton-ratio {
        width: 25%;
        text-align: center;
        float: left;
        padding: 0 10px;
        position: relative;
        margin-bottom: 15px;
        input[type="radio"] {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            z-index: 1;
            opacity: 0;
        }
        &.img-16-9 {
            .preview0 {
                width: 106.67px !important;
                height: 60px !important;
                overflow: hidden !important;
            }
        }
        &.img-1-1 {
            .preview1 {
                width: 100px !important;
                height: 100px !important;
                overflow: hidden !important;
            }
        }
        &.img-4-3 {
            .preview2 {
                width: 106.67px !important;
                height: 80px !important;
                overflow: hidden !important;
            }
        }
        &.img-2-3 {
            .preview3 {
                width: 80px !important;
                height: 120px !important;
                overflow: hidden !important;
            }
        }
        &.img-21-9 {
            .preview4 {
                width: 140px !important;
                height: 60px !important;
                overflow: hidden !important;
            }
        }
        &.img-0 {
            display: none;
        }
    }
    .botton-ratio .content-box {
        background-color: #f8f8f8;
        text-align: center;
        height: 150px;
        line-height: 150px;
        position: relative;
        cursor: pointer;
        overflow: hidden;
    }
    .botton-ratio .content-box>div {
        display: inline-block;
        vertical-align: middle;
    }
    .botton-ratio .content-box>div.ratio-label {
        position: absolute;
        bottom: 0;
        line-height: 12px;
        width: 100%;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 5px;
        color: #fff;
    }
    .botton-ratio.active .content-box {
        background-color: #ddd;
    }
    .botton-ratio input[type="radio"]:checked~.content-box {
        background-color: #ddd;
    }
}

.setImages {
    float: none;
    display: block;
    margin-bottom: 10px;
    overflow: hidden;
}


/** Quick message **/

.quick-msg {
    background-color: #e5e5e5;
    padding: 15px 10px;
    text-align: center;
    margin-bottom: 0px;
    p {
        margin: 0;
    }
}

.table-block .quick-msg {
    background-color: #f2dede;
}

.bulk-change .modal-dialog {
    width: 500px;
    .quick-msg {
        margin-bottom: 20px;
    }
}


/** Address Fields **/

.address-fields {}


/** Plugins **/

.ui-autocomplete {
    z-index: 9999 !important;
}

.ui-autocomplete {
    max-height: 300px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}

.ui-menu .ui-menu-item {
    font-size: 13px;
}

.listing-filters {
    margin-bottom: 15px;
}