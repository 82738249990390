/** Side nav **/

.site-navigation {
    width: 60px;
    background-color: #2E313D;
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100%;
    z-index: 10;

    .nav-bg {
        position: fixed;
        left: 0;
        background-color: #2E313D;
        top: 0px;
        bottom: 0;
        width: 60px;
        z-index: 1;
    }

    .nav-block {
        position: relative;
        z-index: 10;
    }

}

/*_ Nav block _*/
.nav>ul {
    padding: 0;
    margin: 0;
    list-style: none;

    &>li {
        overflow: hidden;
        position: relative;
        width: 60px;
        height: 50px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        line-height: 50px;
        font-family: $regular_font-family;

        &:hover {
            width: 250px;
            overflow: visible;
            background-color: #292C37;
        }

        &.active {
            background-color: #292C37;

            &:before {
                content: "";
                width: 4px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: #8dc63f;
            }
        }

        &>a {
            padding-left: 80px;
            display: block;
            height: 100%;
            color: inherit;
            text-decoration: none;
        }

        &>a:before {
            font-family: "FontAwesome";
            color: #fff;
            font-size: 24px;
            position: absolute;
            left: 0;
            height: 49px;
            top: 0;
            content: "";
            width: 60px;
            text-align: center;
            line-height: 49px;
        }

        /*_ Sub menus _*/

        & .submenu {
            position: absolute;
            right: 0;
            width: calc(100% - 60px);
            background-color: #292C37;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }

        .submenu {

            &>ul {
                margin: 0;
                padding: 0;
                list-style-type: none;

                &>li {
                    height: auto;
                    line-height: 100%;
                    font-size: 14px;
                    position: relative;

                    a {
                        color: inherit;
                        text-decoration: none;
                        display: block;
                        padding: 12px 0 12px 15px;
                    }

                    &:hover,
                    &.active {
                        background-color: #252731;
                    }

                    &.active {
                        color: #8dc63f;
                    }
                }
            }
        }

        /*_ Sub-sub menu _*/

        & .submenu {

            .sub-submenu {
                display: none;
                position: absolute;
                right: -190px;
                width: 100%;
                background-color: #292C37;
                border-top: 1px solid rgba(255, 255, 255, 0.1);
                //bottom: -76px;
                top: 0;
                //top: -75px;

                &>ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        overflow: hidden;

                        &:hover,
                        &.active {
                            background-color: #252731;
                        }

                        &.active {
                            color: #8dc63f;
                        }
                    }

                }

            }

            & li:hover {
                .sub-submenu {
                    display: block;
                }
            }


        }
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12),
        &:nth-child(13),
        &:nth-child(14),
        &:nth-child(15),
        &:nth-child(16),
        &:nth-child(17) ,
        &:nth-child(18){
            .submenu {
                bottom: 49px;

                li {
                    &:hover {
                        .sub-submenu {
                            top: -39px;
                        }
                    }
                }
            }
        }

        /*_ Menu Icons _*/

        &.nav-dashboard>a:before {
            content: "\f0e4 ";
        }

        &.nav-article>a:before {
            content: "\f15c";
        }

        &.nav-config>a:before {
            content: "\f0ad";
        }

        &.nav-user>a:before {
            content: "\f0c0";
        }

        &.nav-notification>a:before {
            content: "\f0f3";
        }

        &.nav-workflow>a:before {
            content: "\f126";
        }

        &.nav-gallery>a:before {
            content: "\f03e";
        }

        &.nav-poll>a:before {
            content: "\f080";
        }

        &.nav-shop>a:before {
            content: "\f07a";
        }

        &.nav-showcase>a:before {
            content: "\f0d6";
        }

        &.nav-press>a:before {
            content: "\f1ea";
        }

        &.photo-booth>a:before {
            content: "\f1c5";
        }

        &.match-center>a:before {
            content: "\f009";
        }

        &.nav-download>a:before {
            content: "\f019";
        }

        &.nav-cassse>a:before {
            content: "CASSSE";
            font-family: "roboto-bold";
            font-size: 12px;
        }

        &.nav-ads>a:before {
            content: "Ads";
            font-family: "roboto-bold";
            font-size: 12px;
        }

        &.nav-adv>a:before {
            content: "Adv";
            font-family: "roboto-bold";
            font-size: 12px;
        }

        &.nav-add>a:before {
            content: "New-Adv";
            font-family: "roboto-bold";
            font-size: 12px;
        }

        &.nav-pr>a:before {
            content: "PR";
            font-family: "roboto-bold";
            font-size: 12px;
        }

        &.nav-news>a:before {
            content: "\f1ea";
        }

        &.nav-bulk-update>a:before {
            content: "\f1ea";
        }

        &.nav-custom-html>a:before {
            content: "\f121";
        }

        &.nav-author>a:before {
            content: "\f2c0";
        }

        &.nav-tag>a:before {
            content: "\f02c";
        }

        &.nav-file>a:before {
            content: "\f016";
        }

        &.nav-media>a:before {
            content: "\f04b";
        }

        &.nav-registration>a:before {
            content: "\f007";
        }

        &.nav-job-html>a:before {
            content: "\f2b5";
        }
        &.nav-entity>a:before {
            content: "\f02c";
        }
        &.nav-upload>a:before {
            content: "\f093";
        }
    }
}