// media Width
$media-phone-min:320px !default;
$media-phone-max:767px !default;
$media-tablet-min:768px !default;
$media-tablet-max:992px !default;
$media-desktop-min:1024px !default;
$media-desktop-max:1200px !default;


// Fonts
$regular_font-family:"roboto-regular",
sans-serif;
$light_font-family:"roboto-light",
sans-serif;
$medium_font-family:"roboto-medium",
sans-serif;
$bold_font-family:"roboto-bold",
sans-serif;
$thin_font-family:"roboto-thin",
sans-serif;
$ultra_font-family:sans-serif !default;
$black_font-family:"roboto-black",
sans-serif;

// $light_font-family:sans-serif !default;
// $regular_font-family:sans-serif !default;
// $medium_font-family:sans-serif !default;
// $bold_font-family:sans-serif !default;
// $ultra_font-family:sans-serif !default;
// $black_font-family:sans-serif !default;

$light_font-weight:300 !default;
$regular_font-weight:400 !default;
$medium_font-weight:500 !default;
$bold_font-weight:700 !default;
$ultra_font-weight:800 !default;
$blaack_font-weight:900 !default;

//Color Scheme
$default-color: #000000 !default;
$primary-color: #df1e26 !default;
$secondary-color: #ffffff !default;
$link-color:#319bc2 !default;
$complementary-color:#000000 !default;
$border-color:#e1e8ed !default;

// Font Setting
$featured-title_font-size:38px !default;

$title_color: $default-color !default;
$title-hover_color:$link-color !default;
$title_font-family:$bold_font-family !default;
$title_font-size:28px !default;
$title_font-weight:$bold_font-weight !default;

$paragraph_font-size:20px !default;
$paragraph_color:$default-color !default;
$paragraph_font-family:$regular_font-family !default;
$paragraph_font-weight:$regular_font-weight !default;

$meta_font-size:16px !default;
$meta_color: $default-color !default;
$meta_font-family:$regular_font-family !default;