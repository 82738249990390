
/** Login Page **/

body.login-page {
    background-color: #e5e5e5;
    height: 100vh;
    position: relative;
    display: table;
    width: 100%;
}

.login-box {
    max-width: 400px;
    margin: auto;
}

.brand-logo {
    margin-bottom: 30px;
    position: relative;
}

.branding {
    height: 70px;
    line-height: 70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    background-image: url('../images/waf-logo.png');
    text-indent: -9999px;
    margin: 0;
}

.login-page .site-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-left: 15px;
    text-align: center;
}

.login-page .site {
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

.login-page .form-submit {
    text-align: center;
}

.panel-login .alert {
    margin-top: 20px;
    text-align: center;
}

.panel-login .panel-footer {
    text-align: center;
}