/** Page Styling **/

/*_ Primary content _*/
.site-primary-content {
    padding-left: 60px;
    position: relative;
    min-height: calc(100vh - 101px);
    background-color: #e5e5e5;
}

.page-view {
    margin: 0 15px;
    position: relative;
}

/*_ Page Head _*/
.page-head {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    padding: 10px 20px;
}

.page-title {
    margin: 0;
    font-size: 32px;
    font-family: $light_font-family;
    text-transform: uppercase;
    color: #121212;
    padding: 0;
}

.page-body {
    position: relative;
    padding-top: 15px;
}

.new-entity {
    margin-top: 10px;
}

.new-entity .alert {
    padding: 8px 10px;
}

/** Page strip **/
.page-strip-controls {
    position: relative;
}

/** Page listing table **/
.table-block {

    position: relative;

    .table-body {
        background-color: #fff;
        position: relative
    }

    .table-head {
        padding: 10px 0;
    }

    .list-count {
        font-style: italic;
        color: #888;
    }

    th {
        font-weight: normal;
        font-family: $medium_font-family;
    }

    tbody tr td,
    thead tr td {
        padding: 10px;
        position: relative;
        font-size: 14px;
        font-family: $regular_font-family;

        .post-title {
            font-family: $medium_font-family;
        }
    }

    .table-actions {
        margin-top: 10px;
        position: relative;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s;
        padding-top: 10px;
        clear: both;
        display: none;


        a {
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px;
            border-left: 1px solid #ddd;

            &:first-child {
                padding-left: 0;
                border-left: none;
            }

            &:last-child {
                padding-right: 0;
            }
        }

    }

    .uploaded-image tr:hover .table-actions,
    tr:hover .table-actions {
        opacity: 1;
        visibility: visible;
        display: block;
    }



    .table-pagination .pagination {
        margin: 0;
    }

    .display-list-count .form-group {
        margin-bottom: 0;
    }

    .display-list-count.element-inline {
        padding-left: 10px;
        margin-left: 10px;
    }

}


/** Add Article **/
.article-id {
    font-style: normal;
}

/** Gallery image listing **/

.image-thumbnail img {
    max-width: 100px;
    width: auto;
    height: auto;
    display: block;
}

.image-thumbnail {
    float: left;
    cursor: pointer;
}

.tblImageinfo {
    margin-left: 110px;
    position: relative;
}

.tblImageinfo span {
    display: block;
    margin-bottom: 4px;
}

.tblImageinfo span.file-name {
    font-family: $medium_font-family, sans-serif;
}

/** Poll page **/

.poll-block {
    .form-submit {
        text-align: center;
    }
}

/** Showcase page **/

.showcase-block,
.waf-list {

    .showcase-item,
    .list-item {
        border: 2px dotted #ccc;
        padding: 10px;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .form-submit {
        text-align: center;
        padding-top: 15px;
    }

}


/*_ Showcase management _*/

/*_ Showcase table _*/
.search-result .checkbox input[type="checkbox"],
.showcase-bucket .checkbox input[type="checkbox"] {
    margin: 0 0 0;
}

.search-result table .checkbox input[type="checkbox"],
.showcase-bucket table .checkbox input[type="checkbox"] {
    margin: 0 0 0 -5px;
}

.showcase-bucket .tab-pane .table-block tbody tr td.tblTitle {
    max-width: 600px;
}

.showcase-bucket .tab-pane .table-block tbody tr td.tblOrder {
    input {
        width: 80%;
    }
}

.search-result .action-icon-btn,
.showcase-bucket .action-icon-btn {
    width: auto;
    height: auto;
    line-height: 1;
    font-size: 18px;
    text-decoration: none;
}

.tblAction {
    text-align: center;
}

.search-result thead,
.showcase-bucket thead {
    background-color: #e5e5e5;
}

/** Channel page **/

.channel-block {
    .multiple-select {
        label {
            width: 100%;
        }

        select {
            float: left;
            margin-right: 5px;

            &.form-control {
                width: 49%;
            }
        }
    }
}

/** USer page **/

.user-social .waf-list .list-item {
    border: none;
    padding: 0;
    margin-bottom: 0;
}


/** Entity Page **/

.entity-sports {
    .tblChecked {

        .radio input[type="radio"],
        .radio-inline input[type="radio"],
        .checkbox input[type="checkbox"],
        .checkbox-inline input[type="checkbox"] {
            position: relative;
            margin-left: 0;
            margin-top: 0;
        }

        .radio,
        .checkbox {
            margin-top: 0;
            margin-bottom: 0;
        }

    }


}

/*-- Entity Module --*/
.entity-module {
    .card {}
}


/** Press Page **/

.press {

    .table {
        border: 1px solid #cccccc;
    }

    .file-path {
        margin: 5px 0;
        display: block;
    }

    // .add-icon, .remove-icon{
    //     color: #ffffff;
    //     background-color: #337ab7;
    //     border-radius: 100%;
    //     border: 1px solid #337ab7;
    //     width: 30px;
    //     height: 30px;
    //     line-height: 30px;
    //     font-size: 24px;
    //     display: block;
    //     text-align: center;
    //     cursor: pointer;
    // }

    // .remove-icon{
    //     font-size: 20px;
    // }

    .multicolumn-layout {
        justify-content: flex-start;
    }

    .press-img-upload {
        .column1 {
            width: 10%;
            margin-right: 25px;
        }

        .column2 {
            width: 20%;
            margin-right: 25px;
        }

        .column3 {
            width: 15%;
            margin-right: 25px;
        }

        .column4,
        .column5 {
            margin-right: 10px;
        }

    }

    //.press-img-upload

    .created-date {
        .column1 {
            width: 10%;
            margin-right: 25px;
        }

        .column2 {
            width: 37%;
        }
    }

}

/*_ Photo Booth Page _*/
.photo-booth {
    .table-block {
        .table-actions {
            visibility: visible;
            opacity: 1;
            display: block;
        }

        .tblImageinfo {
            margin-left: 0;
            background-color: #ffffff;
            padding: 15px 5px;
            margin-bottom: 20px;
        }
    }

    .image-thumbnail {
        float: none;
        margin-right: 10px;

        img {
            height: 100px;
        }
    }

    .tblImageinfo-item {
        display: -webkit-flex;
        display: -moz-flex;
        display: flex;
        margin-bottom: 25px;
    }
}

/*_ Wallpaper List Page _*/

/*_ Add Product Page _*/
.product-img-wrapper {

    .tblImgDetails,
    .tblDescription {
        display: none;
    }
}


/*_ Media Gallery _*/
#medialibrary {
    .waf-search {
        margin-bottom: 20px;

        input[type="text"] {
            width: 65%;
        }

        .btn {
            margin: 0 5px;
        }

    }
}

/* _ LIVEBLOG */
.liveblog {
    .panel-heading {
        .form-group {
            margin: 0;
        }
    }
}

/* Career Modules Page*/
.career-modules {

    .panel-title,
    .panel-body-title {
        font-weight: 600;
    }

    .btn {
        &.btn-edit {
            border-radius: 0;
        }
    }

    .slugurl {
        background-color: #e5e5e5;
    }
}