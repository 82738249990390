@import "variables";

// Bootstrap configuration

    //site bg
    $body-bg:#fff;
    $font-size-base:          10px;

    // Grid
    $grid-columns:12;
    $grid-gutter-width:20px;

        // Small screen / tablet
        $container-tablet:             (740px + $grid-gutter-width);
        $container-sm:                 $container-tablet;

        // Medium screen / desktop
        $container-desktop:            (1000px + $grid-gutter-width);
        $container-md:                 $container-desktop;

        // Large screen / wide desktop
        $container-large-desktop:      (1280px + $grid-gutter-width);
        $container-lg:                 $container-large-desktop;

    // Font Configurations
    $light_font-family:"roboto-light",sans-serif;
    $regular_font-family:"roboto-regular",sans-serif;
    $medium_font-family:"roboto-medium",sans-serif;
    $bold_font-family:"roboto-bold",sans-serif;
    $black_font-family:"roboto-black",sans-serif;

    $font-family-sans-serif:$regular_font-family;
    $text-color:$default-color;    

