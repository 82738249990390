.error{
    .page-body {
        background-image: url(../images/error-icon.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: block;
        width: 221px;
        text-indent: -9999px;
        height: 243px;
        background-position: center;
        margin: auto;
    }
}