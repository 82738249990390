.gallery-view {
    .file-info {
        margin: 10px 0 0;
        font-size: 13px;
        text-align: center;

        .file-name,
        .file-size {
            display: block;
        }

        .file-size {
            font-weight: bold;
            padding-left: 10px;
        }
    }

    .flex-table-wrap {
        margin: 0 -10px;
    }

    .flex-row {
        margin: 15px 0;
    }

    .flexImageinfo {
        @include flexbox();
        box-sizing: border-box;
        position: relative;
        cursor: default;
    }

    .img-wrap {
        flex-basis: 30%;
        max-width: 30%;
        padding: 0 10px;
    }

    .image-thumbnail {
        float: none;
        width: 100%;

        img {
            max-width: 100%;
            width: 100%;
        }
    }

    .img-content {
        flex-basis: 70%;
        max-width: 70%;
        padding: 0 10px;

        .title {
            font-family: $medium_font-family;
            margin-top: 0;
        }
    }

    .img-actions {
        margin-top: 10px;
        //position: relative;
        // visibility: hidden;
        // opacity: 0;
        @include transition(all 0.2s);
        padding-top: 10px;
        clear: both;
        //display: none;


        a {
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px;
            border-left: 1px solid #ddd;
            color: inherit;
            text-decoration: none;

            &:first-child {
                padding-left: 0;
                border-left: none;
            }

            &:last-child {
                padding-right: 0;
            }
        }

    }


    /*_ Grid View _*/
    &.grid-view {
        .flex-table {
            @include flexbox();
            flex-wrap: wrap;
            box-sizing: border-box;

            &>div {
                flex-basis: 25%;
                max-width: 25%;
                padding: 0 10px;
            }
        }

        .img-wrap {
            flex-basis: 100%;
            max-width: 100%;
            padding: 0;
        }

        .image-thumbnail {
            img {
                height: 200px;
                object-fit: cover;
            }
        }

        .flexImageinfo {
            @include flex-direction(column);

            &:hover {

                .img-content,
                .img-actions {
                    // display: block;
                    opacity: 1;
                    height: auto;
                    visibility: visible;
                }

                .img-content {
                    height: 100%;
                }

                // .img-actions {
                //     top: auto;
                //     bottom: 0;
                //     padding: 5px 10px;
                //     z-index: 2;
                //     font-size: 12px;
                // }
            }
        }

        .img-actions,
        .img-content {
            // display: none;
            color: $secondary-color;
            //background: rgba($default-color, 0.75);
            // display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 10px;
            transition: opacity 0.5s ease-in;
            opacity: 0;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }

        .img-actions {
            top: auto;
            bottom: 0;
            padding: 5px 10px;
            z-index: 2;
            font-size: 12px;
        }

        .img-content {
            background: rgba($default-color, 0.75);
            flex-basis: 100%;
            max-width: 100%;

            .title,
            .img-description {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }

            .title {
                font-size: 14px;
                -webkit-line-clamp: 2;
            }

            .img-description {
                font-size: 12px;
                -webkit-line-clamp: 4;
                height: 70px;
            }
        }
    }

}